import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet';
import layoutStyles from '../styles/style.module.scss'

const EducationPage = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>HP Gregorio</title>
            </Helmet>
            <Layout>
                <h1 className={layoutStyles.t1}>Formação</h1>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>
                            Doutorado em Ciências - Área de Oceanografia Física: Instituto Oceanográfico da Universidade de São Paulo - 2014
                            <p className={layoutStyles.parag}>Tese: <a href="https://teses.usp.br/teses/disponiveis/21/21135/tde-11032015-141317/pt-br.php" target="_blank" rel="noopener noreferrer" className={layoutStyles.link}>Oscilações subinerciais na Plataforma Continental Sudeste: estudos numéricos.</a></p>
                        </li>
                        <li className={layoutStyles.t2}>
                            Mestrado em Ciências - Área de Oceanografia Física: Instituto Oceanográfico da Universidade de São Paulo - 2009
                            <p className={layoutStyles.parag}>Dissertação: <a href="http://www.teses.usp.br/teses/disponiveis/21/21132/tde-19012010-165632/pt-br.php" target="_blank" rel="noopener noreferrer" className={layoutStyles.link}>Modelagem numérica da dispersão da pluma do emissário submarino de Santos.</a></p>
                        </li>
                        <li className={layoutStyles.t2}>
                            Bacharelado em Oceanografia - Instituto Oceanográfico da Universidade de São Paulo - 2006
                            <p className={layoutStyles.parag}>Trabalho de Graduação: <a href="http://hpgregorio.net/arquivos/BSc_hpgregorio.pdf" target="_blank" rel="noopener noreferrer" className={layoutStyles.link}>Estudo de massas de água e correntes da Baía do Almirantado - Antártica, durante o verão.</a></p>
                        </li>
                    </ul>
                </nav>
            </Layout>
        </div>
        
    )    
}

export default EducationPage